angular.module('app')
    .factory('tripService', ["$log", "$rootScope", "$http", "$q", "authenticatedStatus", "currentOrganisation", function ($log, $rootScope, $http, $q, authenticatedStatus, currentOrganisation) {
        var self = this;
        var urls = {
            list: '/trips/',
            get: function (organisationReference, bookingReference) {
                return sprintf('/trips/%s/receipt/%s', organisationReference, bookingReference);
            }
        };

        var api = {
            list: function (page, paymentMethodReference, placedBy, placedByReference, from, to) {
                $log.info("Listing trips");
                var params = {
                    page: page,
                    paymentMethodReference: paymentMethodReference,
                    placedBy: placedBy,
                    passengerReference: placedByReference,
                    from: from,
                    to: to
                };
                return currentOrganisation.getOrganisationReference().then(function (reference) {
                    params.organisationReference = reference;
                    return $http.get(urls.list, {params: params})
                        .then(function (resp) {
                            return resp.data;
                        });
                });
            },
            getReceipt: function (organisationReference, bookingReference) {
                $log.info("Getting trip receipt");
                return $http.get(urls.get(organisationReference, bookingReference))
                    .then(function (resp) {
                        return resp.data;
                    });
            },

        };

        return api;
    }]);
